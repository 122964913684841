// extracted by mini-css-extract-plugin
export var viz = "tool-module--viz--17ALI";
export var visualizationSvg = "tool-module--visualizationSvg--1L8qQ";
export var clusters = "tool-module--clusters--3xI_g";
export var projects = "tool-module--projects--2TJER";
export var networks = "tool-module--networks--3bG6E";
export var tools = "tool-module--tools--1z2YE";
export var panel = "tool-module--panel--1I1YW";
export var navLink = "tool-module--nav-link--3AmxP";
export var active = "tool-module--active--rJBmf";
export var open = "tool-module--open--4GutA";
export var customTabs = "tool-module--customTabs--1MMZK";
export var panelHeader = "tool-module--panelHeader--3Ii0Y";
export var closeBtn = "tool-module--closeBtn--1IC0S";
export var legend = "tool-module--legend--1GFnK";
export var buttonsTest = "tool-module--buttonsTest--3apWD";
export var toursControls = "tool-module--toursControls--1GmQ2";
export var toursButton = "tool-module--toursButton--uJRoU";
export var toursBox = "tool-module--toursBox--15BXq";
export var toursText = "tool-module--toursText--3K7vV";
export var listTour = "tool-module--listTour--1feMW";
export var collection = "tool-module--collection--1A7j_";
export var header = "tool-module--header--3SM0s";
export var firstHeader = "tool-module--firstHeader--3uzZg";
export var title = "tool-module--title--ZvaGF";
export var elementsCounter = "tool-module--elementsCounter--1Vp8g";
export var body = "tool-module--body--22pEC";
export var group = "tool-module--group--1x69D";
export var download = "tool-module--download--30GY_";
export var colorLegend = "tool-module--colorLegend--2GJxW";
export var exhibition = "tool-module--exhibition--289aZ";
export var desktop = "tool-module--desktop--14YRk";
export var mobile = "tool-module--mobile--T3Kda";
export var multiple = "tool-module--multiple--21zV4";
export var strategy = "tool-module--strategy--1qyRL";
export var medium = "tool-module--medium--37K58";
export var textLegend = "tool-module--textLegend--2C_JT";
export var subtitleLegend = "tool-module--subtitleLegend--20ukg";
export var boxLegend = "tool-module--boxLegend--39Kez";
export var searchBar = "tool-module--searchBar--3ftiN";
export var hidden = "tool-module--hidden--2DuTn";
export var icon = "tool-module--icon--1-Xjj";
export var customTab = "tool-module--customTab--16AgY";